<template>
  <van-tabs
    id="tab-study-course"
    line-width="0.48rem"
    background="#F8F8F8"
    v-model="active"
  >
    <van-tab title="公共直播" title-class="course-title">
      <div
        id="class"
        v-for="item in liveList"
        :key="item.id"
        @click="openHref(4, item.id)"
      >
        <img class="classImg" :src="item.img_url" />
        <div class="classInfo">
          <h1>{{ item.title }}</h1>
          <span>查看(预约)直播</span>
        </div>
      </div>

      <p class="no-data">-暂无更多-</p>
    </van-tab>
    <van-tab
      :title="item.title"
      title-class="course-title"
      v-for="(item, index) in classList"
      :key="index"
    >
      <!-- <template slot="scoped"> -->

      <div
        id="class"
        v-for="items in classCouseList"
        :key="items.order_id"
        v-if="booleanShowClass(item.id, items.resource_type, index)"
        @click="openHref(item.id, items.resource_id)"
      >
        <img class="classImg" :src="items.goods_img" />
        <div class="classInfo">
          <h1>{{ items.title }}</h1>
          <span>查看回放</span>
        </div>
      </div>

      <p class="no-data">-暂无更多-</p>
    </van-tab>
  </van-tabs>
</template>

<script>
import { Tab, Tabs } from "vant";
import { post } from "@get/http";
import { getUserInfo } from "@view/me/js/";
export default {
  components: {
    vanTab: Tab,
    vanTabs: Tabs,
  },
  props: {
    live: {},
  },
  data() {
    return {
      active: 0,
      classList: [
        {
          title: "直播购物",
          id: 21,
        },
        {
          title: "订阅直播",
          id: 4,
        },
        {
          title: "大班课",
          id: 35,
        },
        {
          title: "线下课",
          id: 29,
        },
        {
          title: "训练营",
          id: 25,
        },
        {
          title: "专栏",
          id: [0, 5, 6, 8],
        },
        {
          title: "小班课",
          id: 31,
        },

        {
          title: "视频",
          id: 3,
        },

        {
          title: "图文",
          id: 1,
        },
        {
          title: "音频",
          id: 2,
        },

        {
          title: "社群",
          id: 7,
        },

        {
          title: "电子书",
          id: 20,
        },
      ],
      classCouseList: [],
      liveList: [],
    };
  },
  methods: {
    openHref(id, value) {
      window.location.href = this.checkUrl(id, value);
    },
    checkUrl(key, value) {
      if (key == 21) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/goods/goods_detail/${value}?type=3`;
      } else if (key == 4) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v2/course/alive/${value}?type=2`;
      } else if (key == 1) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/course/text/${value}?type=2`;
      } else if (key == 2) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/course/audio/${value}?type=2`;
      } else if (key == 3) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/course/video/${value}?type=2`;
      } else if (key == 0 || key == 6 || key == 8) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/course/column/${value}?type=3`;
      } else if (key == 5) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/course/member/${value}?type=3`;
      } else if (key == 35) {
        return `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/course/big_class/${value}?type=2`;
      } else {
        return window.location.href;
      }
    },
    booleanShowClass(id, ids, i) {
      if (typeof id == "number" && id === ids) {
        return true;
      } else if (typeof id == "object") {
        for (let index = 0; index < id.length; index++) {
          if (ids === id[index]) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    await getUserInfo();
    if (this.$store.state.user.xiaoeToken.user_id) {
      let data = await post(
        "/lw.Xiaoet/api",
        {
          data: {
            api_url: "https://api.xiaoe-tech.com/xe.order.list.get/1.0.1",
            method: "POST",
            data: {
              user_id: this.$store.state.user.xiaoeToken.user_id,
              // user_id: "u_6086a97c253ca_hxbSnA7Qn2",
              order_state: 1,
            },
          },
        },
        {
          result: true,
          toast: false,
        }
      );
      this.classCouseList = data.data.list;
    }

    let res = await post(
      "/lw.Xiaoet/api",
      {
        data: {
          api_url: "https://api.xiaoe-tech.com/xe.alive.list.get/1.0.0",
          method: "POST",
          data: {
            // user_id: this.$store.state.user.xiaoeToken.user_id,
            page_size: 50,
            state: 0,
            alive_play_state: 0,
          },
        },
      },
      {
        result: true,
        toast: false,
      }
    );

    // this.liveList = res.data.list;
    let list = res.data.list;
    if (list && list.length != 0) {
      let l = list.filter((item) => {
        if (item.title.indexOf("匠大师网课") == -1) {
          return true;
        }
      });
      this.liveList = l;
    }
    let arr = [];
    if (
      list &&
      list.length != 0 &&
      this.classCouseList &&
      this.classCouseList.length != 0
    ) {
      list.map((item) => {
        for (let index = 0; index < this.classCouseList.length; index++) {
          if (item.id == this.classCouseList[index].resource_id) {
            arr.push(item);
            return item;
          }
        }
      });
      this.$emit("update:live", arr);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#tab-study-course {
  // margin-bottom: rem(32);
  ::v-deep {
    .van-tabs__wrap {
      display: block;
    }

    .van-tabs__nav {
      background: none !important;
    }

    .van-tab {
      padding: 0 rem(20);
      // width: rem(104);
      &:first-of-type {
        padding-left: rem(30);
      }
      flex: none;
      span {
        color: #666;
        font-size: rem(30);
      }
    }
    .van-tabs__nav--line {
      padding-bottom: rem(18);
    }
    .van-tab--active {
      span {
        color: #222222;
        font-weight: bold;
      }
    }
  }
}

#class {
  display: flex;
  width: rem(690);
  background: #ffffff;
  border-radius: rem(16);
  margin: rem(24) auto;
  padding: rem(24);
  box-sizing: border-box;
  .classImg {
    width: rem(224);
    height: rem(168);
    border-radius: rem(8);
    display: block;
  }
  .classInfo {
    padding: 0 rem(24);
    box-sizing: border-box;
    h1 {
      color: #222222;
      font-weight: bold;
      font-size: rem(26);
      line-height: rem(36);
      height: rem(136);
      overflow: hidden;
    }
    span {
      color: #989898;
      font-size: rem(24);
    }
  }
}
.no-data {
  margin-top: rem(20);
  font-size: rem(28);
  text-align: center;
  color: #989898;
}
</style>
