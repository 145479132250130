<template>
  <div>
    <oStudyCalendar :live.sync="liveList"></oStudyCalendar>
    <oClass :live.sync="liveList"></oClass>
  </div>
</template>

<script>
import oStudyCalendar from "./components/studyCalendar";
import oClass from "./components/class";
export default {
  props: {
    live: {},
  },
  created() {},

  data() {
    return {
      liveList: [],
    };
  },
  components: {
    oStudyCalendar,
    oClass,
  },
};
</script>

<style lang="scss" scoped></style>
