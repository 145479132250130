<template>
  <div id="studyCalendar">
    <ul class="ul flex-container">
      <li
        v-for="(item, index) in time"
        :key="item.day"
        @click="actIndex = index"
      >
        <span class="week">{{ item.week }}</span>
        <span class="day" :class="{ act: index == actIndex }">{{
          item.day
        }}</span>
        <svg
          v-if="item.act == true"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5" cy="5" r="5" fill="#FB7E1C" />
        </svg>
      </li>
    </ul>
    <div class="class">
      <h1>
        今日共<span>{{
          this.time[actIndex].data && this.time[actIndex].data.length != 0
            ? this.time[actIndex].data.length
            : 0
        }}</span
        >节课
      </h1>
      <ul class="class-ul">
        <template v-if="time[actIndex].data && time[actIndex].data.length != 0">
          <li
            @click="openHref(time[actIndex], i)"
            class="class-li"
            v-for="(item, i) in time[actIndex].data"
            :key="item.id"
          >
            <div class="info">
              <span>{{ item.alive_start_at.split(" ")[1] }}</span>
              <i>直播</i>
            </div>
            <div class="solid"></div>
            <p class="title">{{ item.title }}</p>
          </li>
        </template>
        <template v-else>
          <p class="no-data">-暂无直播或班课-</p>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    live: {},
  },
  data() {
    return {
      actIndex: 3,
      time: [],
    };
  },
  created() {
    this.time.push(...this.count("prev"));
    this.time.push(...this.count("next", 4));
    // this.init();
  },
  watch: {
    live() {
      this.init();
    },
  },

  methods: {
    openHref(item, index) {
      window.location.href = `https://appg5q9tdm86610.h5.xiaoeknow.com/v2/course/alive/${item.data[index].id}?type=2`;
    },

    init() {
      if (this.live && this.live.length != 0) {
        for (let index = 0; index < this.live.length; index++) {
          let date = new Date(
            this.live[index].alive_start_at.replace(/\-/g, "/")
          ).getDate();
          for (let i = 0; i < this.time.length; i++) {
            if (date == this.time[i].day) {
              this.time[i].act = true;
              if (this.time[i]?.data) {
                this.time[i].data.push(this.live[index]);
              } else {
                this.time[i].data = [{ ...this.live[index] }];
              }
            }
          }
        }
      }
    },
    getPreDayNext() {},
    count(mode = "next", day = 3) {
      let curDate = new Date();
      let arr = [];
      for (let index = 0; index < day; index++) {
        if (mode == "next") {
          arr.push({
            day: new Date(
              curDate.getTime() + 24 * 60 * 60 * 1000 * index
            ).getDate(),
            week: this.toWeek(
              new Date(curDate.getTime() + 24 * 60 * 60 * 1000 * index).getDay()
            ),
            act: false,
          });
        } else {
          arr.unshift({
            day: new Date(
              curDate.getTime() - 24 * 60 * 60 * 1000 * (index + 1)
            ).getDate(),
            week: this.toWeek(
              new Date(
                curDate.getTime() - 24 * 60 * 60 * 1000 * (index + 1)
              ).getDay()
            ),
            act: false,
          });
        }
      }
      return arr;
    },
    toWeek(index) {
      let w = ["日", "一", "二", "三", "四", "五", "六"];
      return w[index];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#studyCalendar {
  background: #fff;
  .ul {
    padding: rem(32);
    box-sizing: border-box;
    > li {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .act {
        color: #fff;
        border-radius: 50%;
        background: #ff4848;
        text-align: center;
      }
      > span {
        color: #5f5f5f;
        text-align: center;
        &:first-of-type {
          display: block;
          width: rem(24);
          height: rem(34);
          font-size: rem(24);
          margin-bottom: rem(30);
        }
        &:last-of-type {
          display: block;
          width: rem(46);
          height: rem(46);
          line-height: rem(46);
          margin-bottom: rem(8);
          font-size: rem(28);
          text-align: center;
        }
      }
      > svg {
        display: block;
        width: rem(10);
        height: rem(10);
      }
    }
  }
  .class {
    margin-top: rem(40);
    padding-bottom: rem(32);
    h1 {
      font-weight: bold;
      font-size: rem(28);
      line-height: rem(40);
      margin-bottom: rem(32);
      padding: 0 rem(30);
      color: #222222;
      span {
        color: #ff4848;
      }
    }
  }
  .class-ul {
    .class-li {
      display: flex;
      align-items: center;
      padding: rem(24) 0;
      box-sizing: border-box;
      background: #f8f8f8;
      border-radius: 16px;
      width: rem(690);
      margin: 0 auto;
      margin-bottom: rem(32);
      &:last-of-type {
        margin-bottom: 0;
      }

      .solid {
        background: #e9e9e9;
        width: rem(2);
        height: rem(84);
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 rem(38);
        box-sizing: border-box;
        span {
          color: #222222;
          font-size: rem(30);
          font-weight: bold;
          display: block;
          margin-bottom: rem(4);
        }
        i {
          display: block;
          width: rem(56);
          height: rem(32);
          border: 1px solid #ff4848;
          box-sizing: border-box;
          border-radius: rem(8);
          text-align: center;
          color: #ff4848;
          font-size: rem(20);
          line-height: rem(32);
        }
      }
      .title {
        box-sizing: border-box;
        padding: 0 rem(32);
        font-size: rem(28);
        line-height: rem(40);
        @include ell2;
        color: #666666;
      }
    }
  }
}

.no-data {
  font-size: rem(28);
  text-align: center;
  color: #989898;
}
</style>
